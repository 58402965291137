<template>
      <div class="row">
        <!-- Hidden per Product Request -->
        <div class="col-6">
          <c-drop-down
            input-id="language"
            :fields='{ value: "isoLanguageName" }'
            :data-source="getLanguages"
            placeholder="Select an option"
            :allow-filtering="true"
            filter-bar-placeholder="Search"
            label="Language"
            :errors="[]"
            for-name="language"
            :selected-index="selectedLanguage"
            @onChange="selectLanguage"
          />
        </div>
        <div class="col-6">
          <c-drop-down
            input-id="timezone"
            :fields='{ value: "timeZoneName" }'
            :data-source="getTimeZones"
            placeholder="Select an option"
            filter-bar-placeholder="Search"
            :allow-filtering="true"
            label="Time Zone"
            :errors="[]"
            for-name="timezone"
            :selected-index="selectedTimezone"
            @onChange="selectTimezone"
          />
        </div>
        <div class="col-6">
          <c-drop-down
            input-id="dateformat"
            :fields='{ value: "format" }'
            :data-source="getDateFormats"
            placeholder="Select an option"
            filter-bar-placeholder="Search"
            label="Date Format"
            :errors="[]"
            for-name="dateformat"
            :selected-index="selectedDateformat"
            @onChange="selectDateformat"
          />
        </div>
        <div class="col-6">
          <c-drop-down
            input-id="currency"
            :fields='{ value: "currencyName" }'
            :data-source="getCountryCurrencies"
            placeholder="Select an option"
            :sort-order="'Ascending'"
            :allow-filtering="true"
            filter-bar-placeholder="Search"
            label="Currency"
            :errors="[]"
            for-name="currency"
            :selected-index="selectedCurrency"
            @onChange="selectCurrency"
          />
        </div>
        <div class="col-12">
          <Button
            size=""
            color="success"
            :outline="false"
            :block="false"
            :disabled="false"
            :href="null"
            :to="null"
            @clicked="updateUser"
          >
            {{saveBttnText}}
          </Button>
        </div>
      </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CDropDown, Button } from '@cendyn/cendyn-frontend-kit';

export default {
  name: 'UserPreferences',
  components: {
    CDropDown, Button
  },
  data() {
    return {
      language: [{ value: '', label: '' }],
      saveBttnText: 'Save Changes',
      alertSuccess: false,
      alert: {
        color: 'success',
        icon: 'check-circle',
        message: 'Your account has been updated.',
        dismiss: false
      }
    };
  },
  computed: {
    ...mapGetters('cendynRegistry', [
      'getLanguages',
      'getCountryCurrencies',
      'getDateFormats',
      'getTimeZones',
      'getUserPref'
    ]),
    ...mapGetters('currentUser', [
      'getUserPref'
    ]),
    selectedLanguage() {
      const index = this.getLanguages.findIndex((x) => x.languageCode === this.getUserPref.languageCode);
      if (index < 0) {return null;}
      return index;
    },
    selectedTimezone() {
      const index = this.getTimeZones.findIndex((x) => x.timeZoneId === this.getUserPref.timeZoneId);
      if (index < 0) {return null;}
      return index;
    },
    selectedCurrency() {
      const index = this.getCountryCurrencies.findIndex((x) => x.currencyCode === this.getUserPref.currencyCode);
      if (index < 0) {return null;}
      return index;
    },
    selectedDateformat() {
      const index = this.getDateFormats.findIndex((x) => x.dateFormatId === this.getUserPref.dateFormatId);
      if (index < 0) {return null;}
      return index;
    }
  },
  methods:{
    ...mapActions('currentUser', [
      'postCurrentUserAsync'
    ]),
    ...mapActions('alerts', [
      'alertAction'
    ]),
    updateUser() {
      this.postCurrentUserAsync( this.getUserPref )
        .then(() => {
          this.alertAction({
            alert: { icon: this.alert.icon, color: this.alert.color, dismissible: this.alert.dismiss, message: this.alert.message}
          }),
          setTimeout(() => { this.$store.commit('alerts/SHOW_ALERT', false); }, 3000);
        });
    },
    selectTimezone(evt) {
      this.getUserPref.timeZoneId = evt.timeZoneId;
    },
    selectCurrency(evt) {
      this.getUserPref.currencyCode = evt.currencyCode;
    },
    selectDateformat(evt) {
      this.getUserPref.dateFormatId = evt.dateFormatId;
    },
    selectLanguage(evt) {
      this.getUserPref.languageCode = evt.languageCode;
    }
  }
};
</script>
